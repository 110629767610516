import React from 'react'

import { GetInContact } from "../components/getInContact";
import Layout from '../components/layout'
import Seo from '../components/seo'
import { HeroText } from '../components/HeroText';

import imageDesignProzess from '../images/design-prozess.png'

const DesignPage = () => (
  <Layout activeMenuItem={'design'}>

    <Seo
      title="Usability" keywords={[`design`, `strategisches design`, `ux`, `user experience`]}
      description={'Wir verstehen Ihre Kunden, entwickeln eine Design-Strategie und bauen Prototypen für Usability-Tests.'}
    />

    <HeroText>Wir verstehen Ihre Kunden, entwickeln eine Design-Strategie und bauen Prototypen für Usability-Tests.</HeroText>

    <div className="content-wrapper">
      <div className="content pure-g">
        <div className="l-box pure-u-1 l-center">
          <img src={imageDesignProzess} alt="Unser Design-Prozess" id="design-process" />
        </div>
      </div>
      <div className="content pure-g principles">
        <div className="l-box pure-u-1">
          <h3>Wir beginnen Sie zu verstehen</h3>
          <p>Am Anfang jedes Projekts steht ein Gespräch. Wir hören zu und stellen Fragen, bis wir Sie und Ihr Geschäft
            verstanden haben. Dann unterhalten wir uns mit Ihren Kunden und finden heraus, wer diese Menschen wirklich
            sind.</p>
        </div>
        <div className="l-box pure-u-1">
          <h3>Wir entwickeln eine Strategie</h3>
          <p>Bereits nach wenigen Gesprächen erstellen wir Persona-Hypothesen und teilen Ihre Kunden entsprechend ihrer
            Verhaltensmuster in unterschiedliche Profile ein. Unsere Profile machen es möglich, die Ziele dieser
            Menschen abzuleiten und daraus eine Design-Strategie zu entwickeln.</p>
        </div>
        <div className="l-box pure-u-1">
          <h3>Wir testen unsere Ideen</h3>
          <p>Ideen sind nur so gut wie ihre Umsetzung. Deshalb bauen wir Prototypen und testen diese mit Ihren Kunden.
            Auf diese Weise verstehen wir Sie und Ihre Kunden immer besser, und können Lösungen liefern, die wirklich
            funktionieren.</p>
        </div>
      </div>
    </div>

    <div className="content-wrapper secondary" id="quote">
      <div className="content pure-g">
        <div className="l-box pure-u-1 l-center">
          <blockquote>“The only source of&nbsp;knowledge is experience.”</blockquote>
          <cite>– Albert Einstein</cite>
        </div>
      </div>
    </div>

    <div className="content-wrapper">
      <div className="content pure-g principles">
        <div className="l-box pure-u-1">
          <h3>Wie wir arbeiten</h3>
          <p>Wir arbeiten iterativ, das heißt wiederholend. Es gibt keine langwierigen Projektphasen, sondern eine
            Strategie bzw. einen Prototypen, die wir regelmässig testen, immer besser verstehen und weiter
            entwickeln.</p>
        </div>
        <div className="l-box pure-u-1">
          <h3>Begriffe, die Sie vielleicht kennen …</h3>
          <p>Unsere Branche ist voll von Fachbegriffen und Modewörtern. Hier eine Liste einiger Bezeichnungen, die
            unsere Arbeit im weitesten Sinne beschreiben:</p>
          <ul>
            <li>User-Experience-Design</li>
            <li>Interaction-Design</li>
            <li>User-Interface-Design</li>
            <li>Service-Design</li>
            <li>Usability-Engineering</li>
          </ul>
          <p>Einige Methoden, die wir Ihnen anbieten können:</p>
          <ul>
            <li>Usability-Tests</li>
            <li>Expert-Reviews</li>
            <li>Prototyping</li>
            <li>A/B-Tests</li>
            <li>User-Research</li>
            <li>Personas und Szenarios</li>
            <li>Requirements-Engineering</li>
          </ul>
        </div>
      </div>
    </div>

    <GetInContact />

  </Layout>
);

export default DesignPage
